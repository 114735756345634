
import { paltformTypes } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const fileList = ref<any[]>([]);
    const formState = ref({
      clubId: undefined,
      maskId: undefined,
    });

    //验证规则
    const rules = {
      maskId: [{ required: true, message: "请输入游戏ID", trigger: "change", type: "number" }],
      clubId: [{ required: true, message: "请输入俱乐部ID", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    const getData = async () => {
      loading.value = true;
      if (props.id) {
        //获取编辑数据
        const { data } = await http.get(`us_club_white_list/${props.id}`);
        formState.value = data.data;
      }

      loading.value = false;
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          //新增
          await http.post("us_club_blacklist_room", formState.value);
          message.success("新增成功");
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    onMounted(() => {
      getData();
    });

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      fileList,
      onClose,
      handleSubmit,
    };
  },
});
