<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value.number="search.data.clubId" placeholder="请输入俱乐部ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="search.data.maskId" placeholder="请输入游戏ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="加入时间">
              <a-row type="flex" :gutter="10" :wrap="false">
                <a-col :flex="1">
                  <a-date-picker show-time v-model:value="search.data.startTime" inputReadOnly placeholder="开始时间" value-format="YYYY-MM-DD HH:mm:ss" style="display: block" />
                </a-col>

                <a-col flex="20px"> <div style="margin-top: 5px">~</div> </a-col>

                <a-col :flex="1">
                  <a-date-picker show-time v-model:value="search.data.endTime" inputReadOnly placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" style="display: block" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="item.maskId + ` - ${item.nickName}`" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <!-- <a-descriptions-item label="游戏ID">{{ item.maskId }}</a-descriptions-item> -->
                <a-descriptions-item label="俱乐部">{{ item.clubId }} -- {{ item.clubName }}</a-descriptions-item>
                <!-- <a-descriptions-item label="userId">{{ item.userId }}</a-descriptions-item> -->
                <a-descriptions-item label="昵称">{{ item.nickName }}</a-descriptions-item>

                <a-descriptions-item label="操作人">{{ item.operMaskId }} - {{ item.operName }}</a-descriptions-item>
                <a-descriptions-item label="加入时间">{{ item.joinTime }}</a-descriptions-item>
              </a-descriptions>

              <template #actions>
                <l-auth-text-button type="danger" auth="us_club_white_list_del_btn" primary @click="del(item)">
                  <DeleteFilled />
                  移除小黑屋
                </l-auth-text-button>
              </template>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="id" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'icon'">
          <a-avatar :size="30" :src="record.icon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button type="danger" auth="us_club_backlist_room_del_btn" primary @click="del(record)">
            <DeleteFilled />
            删除
          </l-auth-text-button>
        </template>
      </template>
    </l-table> -->

    <a-modal v-model:visible="visible" :title="editId == 0 ? '新增' : '编辑'" @cancel="visible = false" destroyOnClose>
      <Edit ref="edit_ref" @close="visible = false" @success="onEditSuccess" v-model:id="editId" />
    </a-modal>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import Edit from "./Edit.vue";
import { message } from "ant-design-vue";
import { showConfirm } from "@/common/utils";

const columns = [
  // {
  //   title: "id",
  //   width: 100,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 100,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部昵称",
    width: 120,
    dataIndex: "clubName",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 120,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "userId",
    width: 120,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 180,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 100,
    dataIndex: "icon",
    ellipsis: true,
  },

  {
    title: "加入时间",
    width: 180,
    dataIndex: "joinTime",
    ellipsis: true,
  },

  {
    title: "",
    key: "action",
    align: "center",
    dataIndex: "action",
    fixed: "right",
    width: 180,
  },
];

export default defineComponent({
  components: {
    Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        clubId: undefined,
        maskId: undefined,
        userId: undefined,
        startTime: undefined,
        endTime: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_blacklist_room", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "删除确认",
        content: "确定删除该条记录吗?",
        onOk: async () => {
          await http.delete(`us_club_blacklist_room/${row.id}`);
          getList();
          message.success("删除成功");
        },
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      editId,
      del,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
    };
  },
});
</script>
